<template>
  <validation-observer ref="form">
    <b-form @submit.prevent>
      <fieldset :disabled="disableForm">
        <b-row>
          <!-- rg -->
          <b-col
            cols="12"
            lg="12"
          >
            <b-form-group
              label="CTPS"
              label-for="v-ctps"
            >
              <b-form-input
                id="v-ctps"
                v-model="dadosEmployee.ctps"
                placeholder="ctps"
              />
            </b-form-group>
          </b-col>

          <!-- setor -->
          <b-col
            cols="12"
            lg="12"
          >
            <b-form-group
              label="Setores"
              label-for="v-setor"
            >

              <v-select
                v-model="dadosEmployee.setor"
                :disabled="disableForm"
                label="nome"
                :options="comboSetores"
              />
            </b-form-group>
          </b-col>

          <!-- funcao -->
          <b-col
            cols="12"
            lg="12"
          >
            <b-form-group
              label="Função"
              label-for="v-funcao"
            >

              <v-select
                v-model="dadosEmployee.funcao"
                :disabled="disableForm"
                label="nome"
                :options="comboFuncoes"
              />
            </b-form-group>
          </b-col>

          <!-- dataAdmissao -->
          <b-col
            cols="12"
            lg="12"
          >
            <b-form-group
              label="Data Admissão"
              label-for="v-dataAdmissao"
            >

              <flat-pickr
                v-model="dadosEmployee.dataAdmissao"
                name="fieldDate"
                class="form-control"
                :readonly="disableForm"
                :config="{ altInput: true, altFormat: 'd/m/Y', enableTime: true, dateFormat: 'Y-m-d\\TH:i:ss\\Z', }"
              />
            </b-form-group>
          </b-col>

          <!-- dataDemissao -->
          <b-col
            cols="12"
            lg="12"
          >
            <b-form-group
              label="Data Demissão"
              label-for="v-dataDemissao"
            >

              <flat-pickr
                v-model="dadosEmployee.dataDemissao"
                name="fieldDate"
                class="form-control"
                :readonly="disableForm"
                :config="{ altInput: true, altFormat: 'd/m/Y', enableTime: true, dateFormat: 'Y-m-d\\TH:i:ss\\Z', }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </fieldset>
      <b-row>
        <!-- submit and reset -->
        <b-col
          v-if="disableForm"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="edit"
          >
            Editar
          </b-button>
        </b-col>
        <b-col
          v-else
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="save"
          >
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="cancel"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('employeesAdministrativeModule', {
      fullEmployee: state => state.fullEmployee,
      comboFuncoes: state => state.comboFuncoes,
      comboSetores: state => state.comboSetores,
    }),
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  watch: {
    disableForm() {
      this.aplicarEstiloDisabilitadoEmCampoData()
    },
  },
  data() {
    return {
      required,
      disableForm: true,
      dadosEmployee: {
        ctps: '',
        dataAdmissao: '',
        dataDemissao: '',
        setor: '',
        funcao: '',
      },
    }
  },
  mounted() {
    this.loadDadosFuncionario(this.fullEmployee)
    this.aplicarEstiloDisabilitadoEmCampoData()
  },
  methods: {
    loadDadosFuncionario(data) {
      this.dadosEmployee.id = data.id
      this.dadosEmployee.ctps = data.ctps
      this.dadosEmployee.dataAdmissao = data.dataAdmissao
      this.dadosEmployee.dataDemissao = data.dataDemissao
      this.dadosEmployee.tituloEleitor = data.tituloEleitor
      this.dadosEmployee.setor = data.setor
      this.dadosEmployee.funcao = data.funcao
    },
    edit() {
      this.disableForm = false
    },
    cancel() {
      this.disableForm = true
      this.loadDadosFuncionario(this.fullEmployee)
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          const data = JSON.parse(JSON.stringify(this.dadosEmployee))
          data.setor = data.setor ? { id: data.setor.id } : null
          data.funcao = data.funcao ? { id: data.funcao.id } : null
          this.$store.dispatch('employeesAdministrativeModule/editEmployeeDadosFuncionario', data).then(response => {
            if (response.response) {
              this.MensagemError('Falha ao Editar', response.response.data.errors[0].message)
              return
            }
            this.$store.dispatch('employeesAdministrativeModule/prepareToEditFullEmployee', response.data).then(() => {
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.cancel()
              this.loadDadosFuncionario(response.data)
            })
          })
        }
      })
    },
  },
}
</script>
